import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticatedUserService } from '../shared/services/authenticated-user.service';
import { RoleEnum } from '../shared/enums/role.enum';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class AdminPermissionsService {
  constructor(private authenticatedUserService: AuthenticatedUserService) {}

  canActivate(): Observable<boolean> {
    const adminSubject = new BehaviorSubject<boolean>(false);
    this.authenticatedUserService.getUserSubject().subscribe({
      next: user => {
        if (user) {
          adminSubject.next(
            user.roleList.includes(RoleEnum.ADMINISTRATOR) ||
              user.roleList.includes(RoleEnum.RESEARCHER)
          );
        }
      },
    });
    return adminSubject.asObservable();
  }
}

export const AdminGuard: CanActivateFn = () => {
  return inject(AdminPermissionsService).canActivate();
};
