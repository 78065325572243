import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, retry, timer } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { InitializationService } from '../services/initialization.service';
import { SignOutService } from '../services/sign-out.service';
import { ToastService } from '../services/toast.service';
import { RefreshTokenService } from '../services/refresh-token.service';
import { AppModalService } from '../services/app-modal.service';

const maxRetries = 1;
const delayMs = 1500;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private initializationService: InitializationService,
    private signOutService: SignOutService,
    private toastService: ToastService,
    private refreshTokenService: RefreshTokenService,
    private appModalService: AppModalService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        switch (err.status) {
          case 0: {
            return next.handle(request).pipe(
              retry({ count: maxRetries, delay: this.delayNotifier }),
              catchError(err => {
                this.appModalService.showDisconnectedDialog();
                return throwError(() => err);
              })
            );
          }

          case 401 /** Unauthorized */: {
            if (request.url.includes('www.zipcodeapi.com')) {
              break;
            }

            localStorage.removeItem('token');
            const refresh = localStorage.getItem('refresh');
            if (!refresh) {
              if (!request.url.endsWith('/authentication')) {
                this.signOutService.signOut();
              } else {
                // we are handling the error for this in the api call itself
              }
              break;
            }

            if (request.url.endsWith('/refresh')) {
              // Refresh api returned 401
              this.signOutService.signOut();
              break;
            }

            // Check if we are already calling the refresh token api
            if (!this.refreshTokenService.callingRefresh) {
              return this.refreshTokenService.refreshToken(refresh!).pipe(
                switchMap(() => {
                  // Retry the original request with the new token
                  const authReq = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${this.initializationService.getReceivedJwt()}`,
                    },
                  });

                  return next.handle(authReq);
                })
              );
            } else {
              // When calling refresh token we do not want to call it again,
              // Instead we want to wait for the refresh token api to update the RefreshTokenCallObservable
              // and then call the other requests with the new token
              // We need to return next.handle() to the interceptor, that's why we are doing things in the switchMap instead of the subscribe
              return new Observable(observer => {
                const subscription = this.refreshTokenService
                  .getRefreshTokenCallObservable()
                  .subscribe(() => {
                    observer.next();
                    observer.complete();
                    subscription.unsubscribe();
                  });
              }).pipe(
                switchMap(() => {
                  // This block will wait till the refresh token subject emit a value
                  const authReq = request.clone({
                    setHeaders: {
                      Authorization: `Bearer ${this.initializationService.getReceivedJwt()}`,
                    },
                  });

                  return next.handle(authReq);
                })
              );
            }
          }

          case 403 /** Forbidden */:
            this.initializationService.navigateToGenericPage(true);
            break;

          case 500 /** Internal Server Error */:
            if (request.url.includes('/authentication/refresh')) {
              // if server is failing on refreshing the token, there is nothing
              // we can do except signing out the user.
              this.signOutService.signOut();
            }
            this.toastService.showError('Oops! Something went wrong from our end.');
            break;
        }

        return throwError(() => err);
      })
    );
  }

  delayNotifier() {
    return timer(delayMs);
  }
}
