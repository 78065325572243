import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { InitializationService } from './initialization.service';
import { SignOutService } from './sign-out.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshTokenService {
  callingRefresh = false;
  private refreshTokenSubject = new Subject<void>();

  constructor(
    private initializationService: InitializationService,
    private signOutService: SignOutService
  ) {}

  getRefreshTokenCallObservable(): Observable<void> {
    return this.refreshTokenSubject.asObservable();
  }

  refreshToken(refreshToken: string): Observable<void> {
    this.callingRefresh = true;
    return new Observable(observer => {
      const subscription = this.initializationService.refreshToken(refreshToken).subscribe({
        next: res => {
          if (res === null) {
            // if the token is expired or not found, the API will return null
            localStorage.removeItem('token');
            this.signOutService.signOut();
          } else {
            // Save new token in local storage
            this.initializationService.saveTokens(res.token, res.refreshToken);
            this.callingRefresh = false;
            // Emit refresh token subject to proceed with requests that are waiting for the refresh to complete
            this.refreshTokenSubject.next();
            observer.next();
            observer.complete();
            subscription.unsubscribe();
          }
        },
        error: () => {
          localStorage.removeItem('token');
          this.signOutService.signOut();
        },
      });
    });
  }
}
