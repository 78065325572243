import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

@Injectable()
export class PermissionsService {
  constructor() {}

  canActivate(): boolean {
    return localStorage.getItem('refresh') != null;
  }
}

export const AuthGuard: CanActivateFn = () => {
  return inject(PermissionsService).canActivate();
};
