import { TemplateRef, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ToastInfo } from '../models/toast-info';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private displayToastSubject = new Subject<boolean>();
  private toastInfo!: ToastInfo | null;

  private showToast(toastInfo: ToastInfo, timesOut = true): void {
    this.toastInfo = toastInfo;
    this.displayToastSubject.next(true);
    if (timesOut) {
      setTimeout(() => {
        this.hideNotification();
      }, 5000);
    }
  }

  hideNotification() {
    this.toastInfo = null;
    this.displayToastSubject.next(false);
  }

  showSuccess(message: string | TemplateRef<string>, timesOut = true): void {
    this.showToast(new ToastInfo(message, 'SUCCESS', timesOut));
  }

  showError(message: string | TemplateRef<string>, timesOut = true): void {
    this.showToast(new ToastInfo(message, 'ERROR', timesOut));
  }

  showInfo(message: string | TemplateRef<string>, timesOut = true): void {
    this.showToast(new ToastInfo(message, 'INFO', timesOut));
  }

  showWarning(message: string | TemplateRef<string>, timesOut = true): void {
    this.showToast(new ToastInfo(message, 'WARNING', timesOut));
  }

  getToastInfo(): ToastInfo {
    return this.toastInfo as unknown as ToastInfo;
  }

  getDisplayToastSubject(): Subject<boolean> {
    return this.displayToastSubject;
  }
}
