import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { signOut } from 'aws-amplify/auth';
import { InitializationService } from './initialization.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SignOutService {
  constructor(
    private router: Router,
    private location: Location,
    private initializationService: InitializationService
  ) {}

  signOut(navigate = true): void {
    signOut({ global: true });
    this.initializationService.setReceivedJwt('');
    localStorage.clear();
    if (navigate) {
      if (this.location.path().includes('/admin')) {
        this.router
          .navigate(['/account/admin/signin'])
          .then(() => window.location.reload())
          .catch();
      } else {
        this.router
          .navigate(['/account/signin'])
          .then(() => window.location.reload())
          .catch();
      }
    }
  }
}
