import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { map, tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { SignInRequest } from '../models/signin-request';
import { SignInCognitoProviderEnum } from '../enums/signin-cognito-provider-enum';
import {
  ResetPasswordOutput,
  confirmResetPassword,
  getCurrentUser,
  resetPassword,
  signIn,
  signInWithRedirect,
  signOut,
  signUp,
} from '@aws-amplify/auth';
import { AuthProvider } from '@aws-amplify/auth/dist/esm/types/inputs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AmplifyService {
  public loggedIn: BehaviorSubject<boolean>;

  constructor(private router: Router) {
    this.loggedIn = new BehaviorSubject<boolean>(false);
  }

  federatedSignIn(provider: SignInCognitoProviderEnum) {
    switch (provider) {
      case SignInCognitoProviderEnum.FACEBOOK:
      case SignInCognitoProviderEnum.GOOGLE: {
        const providerType = provider as unknown as AuthProvider;
        signInWithRedirect({ provider: providerType });
        break;
      }
      case SignInCognitoProviderEnum.SAML: {
        const providerName = `${environment.saml_provider}`;
        signInWithRedirect({
          provider: {
            custom: providerName,
          },
        });
        break;
      }
      case SignInCognitoProviderEnum.MICROSOFT:
        signInWithRedirect({
          provider: {
            custom: provider,
          },
        });
        break;
      default:
        break;
    }
  }

  signUp(email: string, password: string) {
    return from(
      signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email: email,
          },
          autoSignIn: true,
        },
      })
    ).pipe(
      catchError(error => {
        if (error.name === 'UserAlreadyAuthenticatedException') {
          localStorage.clear();
          this.signIn({
            email: email,
            password: password,
          }).subscribe({
            next: () => {
              this.loggedIn.next(true);
            },
            error: () => {
              this.loggedIn.next(false);
              localStorage.clear();
              return of(console.log('error: Incorrect username or password.'));
            },
          });
          return of(console.log('error: User already logged in'));
        } else if (error.name === 'NotAuthorizedException') {
          this.loggedIn.next(false);
          localStorage.clear();
          return of(console.log('error: Incorrect username or password.'));
        }
        this.loggedIn.next(false);
        return error;
      })
    );
  }

  signIn(signin: SignInRequest) {
    return from(signIn({ username: signin.email, password: signin.password })).pipe(
      tap(() => this.loggedIn.next(true)),
      catchError(error => {
        if (error.name === 'UserAlreadyAuthenticatedException') {
          localStorage.clear();
          this.signIn(signin).subscribe({
            next: () => {
              this.loggedIn.next(true);
            },
            error: () => {
              this.loggedIn.next(false);
              localStorage.clear();
              return of(console.log('error: Incorrect username or password.'));
            },
          });
          return of(console.log('error: User already logged in'));
        } else if (error.name === 'NotAuthorizedException') {
          this.loggedIn.next(false);
          localStorage.clear();
          return of(console.log('error: Incorrect username or password.'));
        }
        this.loggedIn.next(false);
        return error;
      })
    );
  }

  isAuthenticated(): Observable<boolean> {
    return from(getCurrentUser()).pipe(
      map(() => {
        this.loggedIn.next(true);
        return true;
      }),
      catchError(() => {
        this.loggedIn.next(false);
        this.router.navigate(['/account/signin']);

        return of(false);
      })
    );
  }

  public signOut(): void {
    from(signOut()).subscribe(() => {
      this.loggedIn.next(false);
      localStorage.clear();
      this.router.navigate(['/account/signin']);
    });
  }

  forgetPassword(email: string): Observable<ResetPasswordOutput> {
    return from(resetPassword({ username: email }));
  }

  resetPassword(email: string, password: string, code: string): Observable<void> {
    return from(
      confirmResetPassword({
        username: email,
        confirmationCode: code,
        newPassword: password,
      })
    );
  }
}
