import { Injectable, inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthenticatedUserService } from '../shared/services/authenticated-user.service';
import { RoleEnum } from '../shared/enums/role.enum';
import { Observable, BehaviorSubject } from 'rxjs';

@Injectable()
export class CompanyPermissionsService {
  constructor(private authenticatedUserService: AuthenticatedUserService) {}

  canActivate(): Observable<boolean> {
    const patientSubject = new BehaviorSubject<boolean>(false);
    this.authenticatedUserService.getUserSubject().subscribe({
      next: user => {
        if (user) {
          patientSubject.next(user.roleList.includes(RoleEnum.COMPANY));
        }
      },
    });
    return patientSubject.asObservable();
  }
}

export const CompanyGuard: CanActivateFn = () => {
  return inject(CompanyPermissionsService).canActivate();
};
