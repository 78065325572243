import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UserCompanyDetails } from '../models/user-company-details';
import { AuthenticationResponse } from 'src/app/account/models/authentication-response';

@Injectable({
  providedIn: 'root',
})
export class CompanyAuthenticatedUserApiService {
  constructor(private httpClient: HttpClient) {}

  getUsersCompanyDetails(): Observable<UserCompanyDetails[]> {
    return this.httpClient.get<UserCompanyDetails[]>(
      `${environment.serverUrl}/api/companyuserrole/companies`
    );
  }

  switchUserCompany(selectedCompanyId: string): Observable<AuthenticationResponse> {
    return this.httpClient.put<AuthenticationResponse>(
      `${environment.serverUrl}/api/company/change/${selectedCompanyId}`,
      {}
    );
  }
}
