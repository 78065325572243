import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { InitializationService } from './shared/services/initialization.service';
import packageJson from '../../package.json';
import { AppModalService } from './shared/services/app-modal.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnDestroy, OnInit {
  // Reading the version from the package.json file
  public clientVersion: string = packageJson.version;
  isLoading = true;
  @ViewChild('disconnectedDialog')
  disconnectedDialog!: ElementRef<HTMLDialogElement>;
  @ViewChild('activeCompanyDialog')
  activeCompanyDialog!: ElementRef<HTMLDialogElement>;
  subscriptions = new Subscription();

  constructor(
    private initializationService: InitializationService,
    private appModalService: AppModalService
  ) {
    this.initializationService.initializeApp();
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.initializationService.getInitUser().subscribe(value => {
        if (value) {
          this.isLoading = false;
        }
      })
    );
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.appModalService.getDisconnectedDialogSubject().subscribe(() => {
        if (!this.disconnectedDialog.nativeElement.open) {
          this.disconnectedDialog.nativeElement.showModal();
        }
      })
    );
    this.subscriptions.add(
      this.appModalService.getActiveCompanyDialog().subscribe(() => {
        if (!this.activeCompanyDialog.nativeElement.open) {
          this.activeCompanyDialog.nativeElement.showModal();
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  reloadPage() {
    window.location.reload();
  }
}
