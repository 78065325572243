import { TemplateRef } from '@angular/core';

export class ToastInfo {
  toastClass: 'SUCCESS' | 'INFO' | 'WARNING' | 'ERROR';
  message: string | TemplateRef<string>;
  timesOut: boolean;

  constructor(
    message: string | TemplateRef<string>,
    toastClass: 'SUCCESS' | 'INFO' | 'WARNING' | 'ERROR' = 'INFO',
    timesOut = true
  ) {
    this.message = message;
    this.toastClass = toastClass;
    this.timesOut = timesOut;
  }
}
