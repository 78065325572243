<p style="display: none">Client version: {{ clientVersion }}</p>
<app-toast />
@if (!isLoading) {
  <router-outlet />
} @else {
  <app-background-image [showBody]="false">
    <ng-container transparent>
      <span class="loading loading-spinner loading-lg"></span>
    </ng-container>
  </app-background-image>
}

<dialog #disconnectedDialog class="modal">
  <form method="dialog" class="modal-box text-start">
    <div class="modal-header flex flex-row items-center justify-center">
      <i class="fa-regular fa-wifi-exclamation px-2 text-lg" aria-hidden="true"></i>
      <h2 class="text-center font-bold" i18n>You are offline!</h2>
    </div>
    <div class="modal-body space-y-5">
      <p i18n class="mx-auto max-w-prose text-center">
        Your browser seems to be having issues reaching our servers.
        <br />
        You can try reloading the page.
      </p>
      <p class="mx-auto max-w-prose text-center" i18n>
        Double check your internet connection,
        <br />
        or switch to a different network.
      </p>
    </div>
    <div class="modal-action">
      <button id="reloadButton" class="btn btn-primary mx-auto" i18n (click)="reloadPage()">
        Reload
      </button>
    </div>
  </form>
</dialog>

<dialog #activeCompanyDialog class="modal">
  <form method="dialog" class="modal-box text-start">
    <div class="modal-header flex flex-row items-center justify-center">
      <h2 class="text-center font-bold" i18n>No Active Companies</h2>
    </div>
    <div class="modal-body space-y-5">
      <p i18n class="mx-auto max-w-prose text-center">
        You can't log in because you don't have any active company.
      </p>
      <p class="mx-auto max-w-prose text-center" i18n>
        Contact your company admin if you think something is wrong.
      </p>
    </div>
    <div class="modal-action">
      <button
        id="reloadButton"
        class="btn btn-primary mx-auto"
        i18n
        (click)="activeCompanyDialog.close()">
        Close
      </button>
    </div>
  </form>
</dialog>
