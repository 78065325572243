import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticatedUserService } from '../shared/services/authenticated-user.service';
import { RoleEnum } from '../shared/enums/role.enum';
import { Observable, BehaviorSubject } from 'rxjs';
import { CompanyPagesService } from './services/company-pages.service';

@Injectable()
export class CompanyPagesPermissionsService {
  constructor(
    private authenticatedUserService: AuthenticatedUserService,
    private companyPagesService: CompanyPagesService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> {
    const patientSubject = new BehaviorSubject<boolean>(false);
    this.authenticatedUserService.getUserSubject().subscribe({
      next: user => {
        if (user && user.roleList.includes(RoleEnum.COMPANY)) {
          this.companyPagesService.getCompaniesDetailsSubject().subscribe({
            next: companies => {
              if (companies && this.companyPagesService.getSelectedCompany()) {
                patientSubject.next(true);
              } else if (companies && !this.companyPagesService.getSelectedCompany()) {
                this.router.navigate(['/company', 'switch']);
              }
            },
          });
        }
      },
    });
    return patientSubject.asObservable();
  }
}

export const CompanyPagesGuard: CanActivateFn = () => {
  return inject(CompanyPagesPermissionsService).canActivate();
};
