import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-background-image',
  templateUrl: './background-image.component.html',
  standalone: true,
})
export class BackgroundImageComponent {
  @Input({ required: false }) showBody = true;
}
