import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppModalService {
  private disconnectedDialogSubject = new Subject<void>();
  private activeCompanyDialogSubject = new Subject<void>();

  public getDisconnectedDialogSubject(): Observable<void> {
    return this.disconnectedDialogSubject.asObservable();
  }

  public showDisconnectedDialog() {
    this.disconnectedDialogSubject.next();
  }

  public getActiveCompanyDialog() {
    return this.activeCompanyDialogSubject.asObservable();
  }

  public showActiveCompanyDialog() {
    this.activeCompanyDialogSubject.next();
  }
}
