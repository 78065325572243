import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CompanyRoleEnum } from 'src/app/shared/enums/company-role.enum';
import { UserCompanyDetails } from 'src/app/shared/models/user-company-details';

@Injectable({
  providedIn: 'root',
})
export class CompanyPagesService {
  private companiesDetails!: UserCompanyDetails[];
  private companiesDetailsObservable = new BehaviorSubject<UserCompanyDetails[] | null>(null);
  private selectedCompanyObservable = new BehaviorSubject<UserCompanyDetails | null>(null);

  constructor(private router: Router) {}

  selectCompany() {
    const company = this.companiesDetails.find(el => el.selected);
    if (company) {
      this.selectedCompanyObservable.next(company);
    }
  }

  getSelectedCompanyId() {
    return this.selectedCompanyObservable.value?.companyId;
  }

  getSelectedCompany() {
    return this.selectedCompanyObservable.value;
  }

  getSelectedCompanySubject() {
    return this.selectedCompanyObservable.asObservable();
  }

  setUserCompanyDetails(details: UserCompanyDetails[]): void {
    this.companiesDetails = details;
    this.companiesDetailsObservable.next(details);
    this.selectCompany();
  }

  getCompaniesDetailsSubject() {
    return this.companiesDetailsObservable.asObservable();
  }

  getCompanyDetails() {
    return this.companiesDetails;
  }

  isCompanyAdmin(companyId: string) {
    return (
      this.companiesDetails.find(el => (el.companyId = companyId))?.companyRoleName ===
      CompanyRoleEnum.COMPANY_ADMINISTRATOR
    );
  }

  isCompanyEditor(companyId: string) {
    return (
      this.companiesDetails.find(el => (el.companyId = companyId))?.companyRoleName ===
      CompanyRoleEnum.COMPANY_EDITOR
    );
  }

  isCompanyView(companyId: string) {
    return (
      this.companiesDetails.find(el => (el.companyId = companyId))?.companyRoleName ===
      CompanyRoleEnum.COMPANY_VIEWER
    );
  }
}
