<div class="hero min-h-screen" style="background-image: url(assets/images/bg-blue.svg)">
  <div class="hero-content mb-auto mt-5 w-full flex-col p-0 py-3 text-center sm:px-1 md:mt-20">
    <div class="w-64">
      <img src="assets/logos/thrivable_print_rev.svg" alt="Thrivable white logo" />
    </div>
    @if (showBody) {
      <div class="h-full w-full overflow-auto bg-base-100 p-6 sm:card sm:h-fit sm:w-96">
        <ng-content select="[body]" />
      </div>
    } @else {
      <div class="h-full w-full items-center overflow-auto p-6 text-white sm:card sm:h-fit">
        <ng-content select="[transparent]" />
      </div>
    }

    <div class="text-white">
      <ng-content select="[footer]" />
    </div>
  </div>
</div>
