import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin/admin.guard';
import { MemberGuard } from './member/member.guard';
import { CompanyGuard } from './company/company.guard';

const routes: Routes = [
  {
    path: 'account',
    loadChildren: () => import('./account/account.module').then(module => module.AccountModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, AdminGuard],
    loadChildren: () => import('./admin/admin.module').then(module => module.AdminModule),
  },
  {
    path: 'company',
    canActivate: [CompanyGuard],
    loadChildren: () => import('./company/company.module').then(module => module.CompanyModule),
  },
  {
    path: 'member',
    canActivate: [AuthGuard, MemberGuard],
    loadChildren: () => import('./member/member.module').then(module => module.MemberModule),
  },
  {
    path: '**',
    redirectTo: 'account',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
